@use '../../../styles/_tools' as *
@use '../../../styles/_vars' as *

.GestionDesOffres
    .blue-txt
        color: #00358c
    .fa-list-ul
        height: 25px
        width: 25px
        border: 1px solid $main_color
        border-radius: 50%
        font-size: 15px
        margin-right: 4px
        text-align: center
        line-height: 21px
    .row
        @extend %Flex-between
        column-gap: 8px
        a
            font-size: 14px
            padding: 12px 16px
            background-color: $ligth_blue
            color: $main_color
            transition: $transition
            // white-space: nowrap
            text-align: center
            &:hover
                box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2)
            i
                margin-left: 12px
@media (max-width:400px)
    .GestionDesOffres .row 
        h3 
            font-size: 16px
        a
            padding: 8px 0px
            font-size: 14px

