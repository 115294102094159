
@use '../../../styles/tools' as *
@use '../../../styles/_vars' as *
.controler
    $inputBorder: 1px solid #e4e4e4
    overflow: hidden
    position: relative
    >div.act
        visibility: visible
        max-height: 800px
    .megaFilter
        max-height: 0
        padding-inline: 22px
        background-color: $ligth_blue
        overflow: hidden
        transition: max-height .6s linear
        .flexContainer
            display: flex
            align-items: flex-start
            justify-content: space-between
        .reset
            padding-left: 32px
            align-self: center
            .fa-rotate-right 
                transform: rotate(-130deg)
                margin-inline: 2px
            button
                font-weight: 500
                background-color: white
                border: $inputBorder
                color: $main_color
                padding: 10px
                &:hover
                    box-shadow: 0px 2px 5px rgba(0,0 ,0 ,.2 )

    .filter-l
        margin-block: 16px 32px
        padding-right: 6px
        border-right: 1px solid $yellow
        flex-grow: 1
        @include carts(40px,200px,12px, 2px)
        column-gap: 12px
    .checkf div
        >label
            white-space: nowrap
            input
                margin-right: 4px
                accent-color: $main_color
    .fields
        color: $main_color
        font-size: 12px
        >label
            font-size: 12px
            margin-bottom: 12px
            display: block
    .dateInput
        width: 100%
        position: relative
        padding: 8px
        background-color: white
        display: block
        border: 1px solid #e4e4e4
        color: $text_light_blue
        cursor: pointer
        input
            visibility: hidden
            @extend %Abs-center
        .fa-calendar-day
            @extend %Abs-center-Y
            right: 16px
            color: $yellow
    .actions
        padding: 22px
        background-color: $ligth_blue
        flex-wrap: wrap
        position: relative
        .Actions-r
            justify-content: flex-end
            width: fit-content
        .Actions-l
            flex-grow: 1
            width: clamp(390px, 450px, 45%)
            max-width: 550px
    .actions-section
        flex-shrink: 0
        button
            background-color: $ligth_blue1
            border: none
            flex-grow: 1
            font-size: 13px
            padding: 12px
            color: $main_color
            transition: box-shadow $transition,transform $transition
            white-space: nowrap
            &:hover
                box-shadow: 0px 2px 5px rgba(0,0 ,0 ,.2 )
            .fa-angle-down
                transition: transform $transition .1s
            &.act .fa-angle-down
                transform: rotate(180deg)
            &.redBtn
                background-color: #ce4859
                color: white
        .fa-angle-down
            color: $yellow

    .actions-section,.actions
        @extend %Flex-between
        gap: 16px

    .searchInput
        position: relative
        width: 60%
    .fa-magnifying-glass
        @extend %Abs-center-Y
        right: 8px
        color: $yellow
    [type="search"]
        width: 100%
        min-width: 230px
        font-size: 14px
        padding: 12px
        padding-right: 26px
        border: 2px solid $main_color
        transition: outline $transition
        outline: 3px transparent solid
        &:focus
            outline-color: #d1dcf3
        &::placeholder
            color: $main_color
            opacity: .8

@media (max-width:900px)
    .controler >.actions
        >div.Actions-l
            max-width: 100%
        >div.Actions-r
            flex-grow: 1
    .container .browseTable 
        flex-direction: column-reverse
        row-gap: 12px
@media (max-width:490px)
    .controler >.actions
        >div.Actions-l,>div.Actions-r
            flex-direction: column
            >*
                width: 100%
@media (max-width:750px)
    .controler .megaFilter .flexContainer
        flex-direction: column
        align-items: unset
        justify-content: unset
        .reset
            margin-bottom: 32px
            padding-left: 0
        .filter-l
            border: none
.yellowCircle
    display: inline-block 
    background-color: white 
    height: 12px
    width: 12px
    border-radius: 50%
    border: 1px #ccc solid
    position: relative
    &::after
        @extend %Abs-center
        content: ''
        display: inline-block 
        height: 6px
        width: 6px
        border-radius: 50%
[type="radio"]:checked + .yellowCircle::after
        background-color: $yellow 
        