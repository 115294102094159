@use '../../../styles/_vars' as *
@use '../../../styles/_tools' as *



.selectEl 
    position: absolute !important 
    left: 0
    height: 100%
    width: 100%
    >div:nth-of-type(1)
        height: 100%
        opacity: 0

.statistiques-filter
    position: relative
    background-color: $ligth_blue
    width: fit-content 
    margin-inline: auto
    padding: 12px 16px
    @extend %Flex-between
    column-gap: 12px
    cursor: pointer
    transition: background-color $transition
    .fa-angle-down
        color: $yellow
        margin-left: 18px
    &:hover
        background-color: $ligth_blue1
.chart-container
    margin-top: 32px
    box-shadow: 0px 0 16px rgba(0, 0, 0,.12 )
    max-width: 800px
    margin-inline: auto
    padding: 12px 6px 16px
    margin-bottom: 56px
    border-radius: 12px
    
    .chart-wrapper-t
        height: 500px
    span
        display: block
        margin-block: 12px
        text-align: center
