@use '../styles/_tools' as *
@use '../styles/_vars' as *

.selectInput .selectContainer
    $inputBorder: 1px solid #e4e4e4
    color: $text_light_blue
    position: relative
    width: 100%
    display: block
    background-color: white
    border: $inputBorder
    font-size: 12px
    padding: 8px
    padding-right: 25px
    .fa-angle-down
        @extend %Abs-center-Y
        right: 8px
        color: $yellow
    >select
        cursor: pointer
        appearance: none
        width: 100%
        position: absolute
        height: 100%
        left: 0
        top: 0
        opacity: 0
