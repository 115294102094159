%Abs-center
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)

%Abs-center-Y
    position: absolute
    top: 50%
    transform: translateY(-50%)

%Abs-center-X
    position: absolute
    left: 50%
    transform: translateX(-50%)

//      Flex Tools
%Flex-center
    display: flex
    justify-content: center
    align-items: center

%Flex-between
    display: flex
    align-items: center
    justify-content: space-between

%Flex-evenly
    display: flex
    align-items: center
    justify-content: space-evenly

%Flex-around
    display: flex
    align-items: center
    justify-content: space-around
%Flex-start
    display: flex
    align-items: center
    justify-content: flex-start
%Flex-end
    display: flex
    align-items: center
    justify-content: flex-end

//      Hover tools
%hover-1
    filter: saturate(1.2)
    transform: scale(1.07)

//      Random Tools
@mixin C-Text($h)
    height: $h
    line-height: $h

@mixin circleT($r)
    @include C-Text($r)
    text-align: center
    width: $r
    height: $r
    border-radius: 50%

@mixin squareT($h, $r)
    @include C-Text($h)
    text-align: center
    width: $h
    border-radius: $r

@mixin circle($r)
    width: $r
    height: $r
    border-radius: 50%

@mixin square($h, $r)
    height: $h
    width: $h
    border-radius: $r

@mixin lines($n)
    overflow: hidden
    text-overflow: ellipsis
    display: -webkit-box
    -webkit-line-clamp: $n
    line-clamp: $n
    -webkit-box-orient: vertical

%Fill-screen
    width: 100vw
    height: 100vh
    position: absolute
    top: 0
    left: 0

%Fill-container
    width: 100%
    height: 100%
    position: absolute
    top: 0
    left: 0

%Center-Fixed
    position: fixed
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
%Center-Fixed-Y
    position: fixed
    top: 50%
    transform: translateY(-50%)
%Center-Sticky-Y
    position: sticky
    top: 50%
    transform: translateY(-50%)

//          Grid tools
@mixin carts($minR, $minC, $gapR, $gapC)
    grid: repeat(auto-fit, minmax($minR, 1fr)) / repeat( auto-fit, minmax($minC, 1fr))
    display: grid
    row-gap: $gapR
    column-gap: $gapC

//      Container
.container
    @media (max-width: 599px)
        margin-inline: 16px
    @media (min-width: 600px) and (max-width: 1240px)
        margin-inline: 32px
    @media (min-width: 1240px) and (max-width: 1439px)
        margin-inline: 40px
    @media (min-width: 1440px)
        width: 1340px
        margin-inline: auto

// Reset 
*
    box-sizing: border-box
button
    cursor: pointer
html,body
    max-width: 100vw
    margin: 0
    padding: 0
    scroll-behavior: smooth
ul,ol
    padding: 0
    margin: 0
    list-style: none
a
    text-decoration: none
    color: black
figure
    margin: 0
