
@use './tools' as *
@use './_vars' as *

.mainTable
    border-collapse: collapse
    width: 100%
    text-align: left
    font-size: 14px
    .notFound
        text-align: center
        font-size: 22px
        display: none
    thead
        background-color: $main_color
        color: white
        th
            font-weight: 400
            padding: 12px 8px
    tbody tr:nth-of-type(2n+1)
        background-color: $ligth_blue
    td
        color: $main_color
        font-weight: 300
        padding: 16px 8px
        img
            width: 24px
            transition: box-shadow $transition
            cursor: pointer
            background-color: white
            &:hover
                box-shadow: 0px 2px 5px rgba(0,0 ,0 ,.2 )
        [type='checkbox']
            height: 22px
            width: 22px
            accent-color: $main_color
            cursor: pointer
    .fa-eye ,.fa-pen,.fa-trash-can
        $size: 32px
        background-color: white
        color: $main_color
        height: $size
        width: $size
        line-height: $size
        text-align: center
        cursor: pointer
        border: 1px solid #00368c42
        transition: box-shadow $transition
        &:hover
            box-shadow: 0px 2px 5px rgba(0,0 ,0 ,.2 )
