@use './_tools' as *
@use './_vars' as *

body
    font-family: "Poppins", sans-serif

h2
    text-align: center
    margin-block: 56px
    color: $main_color
    font-size: 38px
    font-weight: 400
    position: relative
    &::after
        content: ''
        @extend %Abs-center-X
        background-color: $yellow
        height: 2px
        width: 56px
        bottom: -12px

.fieldTitle
    font-weight: 500
    position: relative
    font-size: 22px
    &::after
        content: ""
        position: absolute
        left: 0
        top: calc( 100% + 8px )
        background-color: $yellow
        height: 2px
        width: 40px
.downloadCv
    @extend %Flex-center
    flex-direction: row
    background-color: #f2f4f9
    padding: 16px
    column-gap: 16px
    margin-block: 12px
    border: 1px solid #ddd
    cursor: pointer
    span
        font-size: 12px
        font-style: italic
    i
        font-size: 30px

