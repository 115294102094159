@use '../../../styles/_tools' as *
@use '../../../styles/_vars' as *
    
body
    color: $main_color
.editing
    background-color: $ligth_blue
    padding: 32px 16px
    .selectInput >label
        white-space: nowrap
    >.container
        row-gap: 16px
        column-gap: 8px
        flex-wrap: wrap
        @extend %Flex-evenly
.editing .fields
    row-gap: 16px
    @extend %Flex-center
    column-gap: 16px
    flex-direction: row
    .title
        font-size: 14px
        white-space: nowrap
.radioLabels
    .labelsContainer
        [type="radio"]
            display: none
            &:checked + label
                background-color: var(--bg)
        label
            margin-inline: 4px
            transition: background-color $transition
            width: 80px
            cursor: pointer
            padding: 8px
            text-align: center
            font-size: 14px
            display: inline-block
            border: 1px solid
            &:hover
                background-color: var(--bg)
.downloadBtn
    color: white
    padding: 12px 16px
    border: 0
    border-left: 4px solid $yellow
    font-size: 14px
    background-image: linear-gradient(to right,#00358c,#1b58bc)
    &:hover
        animation: gradient 1s infinite alternate
    i
        margin-inline: 4px
        font-size: 18px

.updateCandidat
    margin: 30px 0 0 0

.preview0
    margin-block: 64px
    .downloadCv
        margin-right: 16px
    .grid
        @include carts(40px,230px,12px, 12px)
    .flex-container
        grid-column: span 2

    .flex
        width: 100%
        @extend %Flex-start
        flex-flow: row wrap
        column-gap: 12px
        @media (min-width:900px)
            flex-wrap: nowrap
        >div
            flex-grow: 1
    h3
        padding-left: 32px
    .wrapper
        padding: 32px
        padding-right: 4px
        border: #c1d8ff 1px solid
    .title
        font-size: 14px
        margin-block: 8px 12px
        b
            font-weight: 500

footer
    text-align: right
    font-size: 12px
    padding-bottom: 16px
@media (max-width:440px)
    h2
        font-size: 28px
    .radioLabels .labelsContainer > label
        width: 40%

@media (max-width:580px)
    .preview0 .flex-container
        grid-column: unset
    .editing
        padding-inline: 8px
    .fields
        flex-direction: column
    .labelsContainer
        flex-wrap: wrap
        display: flex
        column-gap: 12px
        row-gap: 12px
        justify-content: space-around
        align-items: center

// @keyframes gradient
//     @for $i from 1 through 100
//         #{$i}%
           

@keyframes gradient
    @for $i from 0 through 100
        #{$i * 1%}
           background-image: linear-gradient(to right, #00358c #{$i*1%} , #1b58bc)

.cv-btn-link
    color: #00358c

.btn-update
    display: flex
    justify-content: flex-end