@use '../../styles/_tools' as *
@use '../../styles/_vars' as *

.loginContainer
    display: flex
    flex-direction: column
    padding-block: 32px
    min-height: 100vh
    align-items: center
    .logo
        @extend %Flex-center
        flex-direction: column
        img
            width: 140px
        h1
            font-size: 40px
            font-weight: 600
            position: relative
            margin-block: 16px 40px
            &::after
                content: ''
                display: inline-block
                background-color: $yellow
                top: 100%
                @extend %Abs-center-X
                width: 200px
                height: 4px
.loginContainer
    form
        background-color: #eee9
        padding: 48px 32px
        border-radius: 8px
        max-width: 320px
    label
        display: block
        margin-bottom: 16px
        margin-inline: auto
        input
            width: 100%
            padding: 10px 12px
            padding-right: 32px
            border: 1px solid #ddd
            display: block
    .password
        position: relative
        svg
            @extend %Abs-center-Y
            right: 16px
    button
        background-image: linear-gradient(90deg,#00358c,#1b58bc)
        border: none
        border-left: 4px solid #f0ab00
        margin-top: 40px
        color: #fff
        font-size: 14px
        padding: 12px 32px
        margin-inline: auto
        display: block
        position: relative
        >svg
            @extend %Abs-center-X
            left: 12px
            animation: spin 1s linear infinite

@keyframes spin
    from
        transform: rotate(0deg)

    to
        transform: rotate(360deg)

.loginContainer .labelTitle
    margin-bottom: 4px
    display: block
    color: #333
.errorMsg
    color: #f00
    font-size: 12px

@media (min-width:860px)
    .loginContainer
        flex-direction: row
        justify-content: space-evenly
        .row
            display: flex
            flex-direction: row
            column-gap: 24px
        form
            min-width: 500px
        .logo img
            width: 200px


.loginContainer .selectInput 
    flex-grow: 1
    .selectContainer
        padding: 10px 32px 10px 12px
        width: 100%
    span 
        color: #747474
