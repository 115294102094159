@use './_tools' as *
@use './_vars' as *

.deleteWindow
    @extend %Center-Fixed
    text-align: center
    padding: 32px
    min-width: 300px
    max-width: 90%
    width: 650px
    border: 1px #126cff solid
    background-color: white
    z-index: 12
    box-shadow: 0 0 0 100vw rgba(0,0 ,0 ,.2 )
    color: $main_color
    opacity: 0
    max-height: 70vh
    transform: translate(-50%,120%)
    animation: goUp $transition forwards
    .actions
        @extend %Flex-center
        column-gap: 12px
        .delete,.cancel
            background-color: white
            font-size: 16px
            height: 48px
            display: bolck
            text-align: center
            line-height: 48px
            width: 120px
            box-sizing: border-box
            padding: 0
            &:hover
                filter: brightness(.95)
        .cancel
            color: $main_color
            border: $main_color 1px solid
        .delete
            color: $red_false
            border: $red_false 1px solid
            i
                margin-inline: -4px 4px

@keyframes goUp
    100%
        opacity: 1
        transform: translate(-50%,-50%)
