
@use '../../../styles/_tools' as *
@use '../../../styles/_vars' as *

.preview
    overflow-y: scroll
    width: 830px
    min-width: 300px
    border: 1px #126cff solid
    background-color: white
    @extend %Center-Fixed
    padding: 32px
    max-width: 90%
    z-index: 12
    box-shadow: 0 0 0 100vw rgba(0,0 ,0 ,.2 )
    color: $main_color
    opacity: 0
    max-height: 70vh
    transform: translate(-50%,120%)
    animation: goUp $transition forwards

    .flex-container
        grid-column: span 2
    .flex
        width: 100%
        @extend %Flex-start
        flex-flow: row wrap
        column-gap: 12px
        >div
            flex-grow: 1
    .fa-xmark
        $size: 55px
        background-color: white
        position: sticky
        color: $main_color
        top: -20px
        margin-top: -40px
        height: $size
        width: $size
        line-height: $size
        text-align: center
        cursor: pointer
        border: $main_color 1px solid
        left: 95%
        border-radius: 50%
        font-size: 40px
        transition: background-color .3s
        &:hover
            background-color: #eee
    .title
        margin-bottom: 16px
    .title b
        margin-right: 12px
        font-weight: 500
    h3
        text-align: center

    .grid
        @include carts(40px,310px,12px, 12px)
@media (max-width: 500px)
    .preview
        .title
            margin-bottom: 4px
        h3
            margin-bottom: 0
        h4
            margin-block: 16px 18px

@media (max-width: 700px)
    .preview .fa-xmark
        left: 90%