@use '../../../styles/_tools' as *
@use '../../../styles/_vars' as *

.Ajouteoffre .formEmploi
    $border: 1px solid #dadada
    @extend %Flex-evenly
    align-items: flex-start
    flex-wrap: wrap
    max-width: 920px
    column-gap: 16px
    margin-inline: auto
    row-gap: 32px
    &.editForm .fieldTitle
        margin: 15px 0 0 0
        padding: 0
        font-size: 18px
        &:nth-child(1)
            margin:0
    .selectContainer 
        padding-block: 12px
        padding-left: 12px
        >span
            color: $main_color
    [type='text'],[type='email']
        width: 100%
        padding: 13px 12px
        border: $border
        &::placeholder
            color: $main_color
            opacity: 1
    .radioInput
        font-size: 14px
        @extend %Flex-between
        [type='radio']
            accent-color: $main_color
    .fields
        color: $main_color
        font-size: 12px
        >label
            font-size: 12px
            display: block

        .dateInput
            width: 100%
            position: relative
            padding: 12px
            background-color: white
            display: block
            border: $border
            cursor: pointer
        input
            visibility: hidden 
            @extend %Abs-center
        .fa-calendar-day
            color: $yellow
            @extend %Abs-center-Y
            right: 10px
            font-size: 18px
    textarea
        resize: none
        min-height: 120px
        font-family: sans-serif
        font-size: 15px
        &::placeholder
            opacity: 1
            color: $main_color
        &[name='description'], &[name='jobDescription'], &[name='profil_recherche'], &[name='autre']
            border: $border
            width: 100%
            padding: 12px 16px

.Ajouteoffre
    .col-1
        height: 345px
        display: flex
        flex-direction: column
        justify-content: space-between
    .col
        flex:1
        max-width: 430px
        min-width: 320px
        gap: 15px
    .EditorIcon
        color: red
    .downloadBtn
        margin: auto
        display: block
        width: fit-content
        margin-block: 32px
        padding-inline: 24px
    .col-2 >div:first-child
        margin-top: 0
