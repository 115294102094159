@use '../../../styles/_tools' as *
@use '../../../styles/_vars' as *
@use '../../../styles/_basic' as *

h3
    font-size: 26px
    font-weight: 500
    color: $main_color
.pageNumber,.pageNumber ul,.browseTable
    @extend %Flex-between
    flex-flow: row nowrap
.browseTable
    color: $main_color
    margin-block: 32px
    justify-content: space-between
    .pageNumber
        column-gap: 10px
        font-size: 13px
        button
            color: $main_color
            background: none
            border: none
        > button.empty
            color: #999
        ul
            column-gap: 8px
        ul button:not(.pre)
            $size: 22px
            width: $size
            height: $size
            border-radius: 50%
            display: block
            line-height: calc($size - 2px )
            text-align: center
            &.act
                border: 1px #00368c43 solid
                background-color: #e5eaf3
    label
        font-size: 12px
    select
        background-color: white
        border: 2px solid rgba(0, 54, 140, 0.24)
        margin-left: 8px
        width: 50px
footer
    color: $main_color
    text-align: right
    padding-block: 12px
    border-top: 1px #00368c58 solid
    font-size: 10px
.tableContainer
    max-width: 100%
    [role="rowgroup"] [role="row"] div:not(div:first-child):not(div:last-child)
        display: flex
        align-items: center
        justify-content: flex-start
        div
            width: 100%
            white-space: normal !important

    /* overflow-x: scroll */

.rdt_TableCol
    background-color: #00358c
    color: white

.cv_icon
    background-color: #fff
    cursor: pointer
    transition: box-shadow .3s
    width: 24px

.rdt_TableCell
    font-weight: 300
    padding: 16px 4px !important
    font-size: 14px
.rdt_TableCol
    padding-inline: 4px !important

.rdt_TableRow:nth-of-type(odd)
    background-color: #f2f4f9


.actions-btn
    display: flex
    gap: 15px

.actions-btn button
    outline: none
    padding: 0
    border: 0
