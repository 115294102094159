.preview0 .flex-container.span-grid-1 
    grid-column: span 1

.preview0 .wrapper.editWrapper
    padding-right: 32px

.editContainer 
    margin: 0 auto

.editContainer
    align-items: center

.editContainer label input
    width: 100%
    padding: 10px 12px
    border: 1px solid #ddd
